import Q from 'q';
import _ from 'underscore';

import HotelDomesticCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/hotel-domestic-city-selector/1.0.0/index.vue';

export default {
    components: {
        HotelDomesticCitySelector,
    },
    data() {
        return {
            selected_entity_aHotelDomesticCitySelector: null,

            //在这里赋值不能正常初始化，暂时解决不了
            // selected_entity_aHotelDomesticCitySelector: {
            //     cityId: `70008`,//中山
            // },
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_entity_aHotelDomesticCitySelector);
        },

        __btn_set_value_click() {
            const __this = this;
            __this.selected_entity_aHotelDomesticCitySelector = {
                cityId: `70008`,//中山
            };
        },

        __btn_reset_value_click() {
            const __this = this;
            __this.selected_entity_aHotelDomesticCitySelector = {};
        },

        __btn_get_selected_entity_list_click() {
            const __this = this;
            const selected_entity_list = __this.$refs.aHotelDomesticCitySelector.get_selected_entity_list();
            console.log(selected_entity_list);
        },
    },
    watch: {
        selected_entity_aHotelDomesticCitySelector(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
            // const selected_entity_list = __this.$refs.aHotelDomesticCitySelector.get_selected_entity_list();
            // console.log(selected_entity_list);
        },
    },
    created() {
        const __this = this;
        //在这里赋值不能正常初始化，暂时解决不了
        // __this.selected_entity_aHotelDomesticCitySelector = {
        //     cityId: `70008`,//中山
        // };
    },
    mounted() {
        const __this = this;
        // __this.selected_entity_aHotelDomesticCitySelector = {
        //     cityId: `70008`,//中山
        // };
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
